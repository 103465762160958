import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'


class Index extends React.Component {
  constructor() {
    super()
  }

  componentDidMount() {
    // const isBrowser = typeof screen !== 'undefined';
    // if (isBrowser) {
    //   // lock the portrait mode
    //   screen.orientation.lock('portrait').then(() => {
    //     console.log('locked at portrait mode')
    //   }).catch(() => {
    //     console.log('error locking the portrait mode')
    //   })
    // }
  }

  render() {
    const ProjectsWrapper = styled.div`
      ${tw`flex flex-wrap justify-between mt-8`};
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 1200px) {
        grid-gap: 3rem;
      }
      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
      }
    `

    const AboutHero = styled.div`
      ${tw`flex flex-col lg:flex-row items-center mt-8`};
    `

    const Avatar = styled.img`
      ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
    `

    const AboutSub = styled.span`
      ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
    `

    const AboutDesc = styled.p`
      ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
    `

    const ContactText = styled.p`
      ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
    `

    const Footer = styled.footer`
      ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
    `
    return (
      <>
        <Layout />
        <Parallax pages={5}>
          <Hero offset={0}>
            <BigTitle>
              Hi, I'm <br /> Mazhar Ahmed.
            </BigTitle>
            <Subtitle>
              Entrepreneur, Product Enthusiast, Tech Expert, Designer, Brander for {
                new Date().getFullYear() - 2007
              }+ years <br />
              CTO @ <a href="https://oceanize.co.jp/" target="_blank">Oceanize</a>.
            </Subtitle>
          </Hero>
          <Projects offset={1}>
            <Title>Experiences</Title>
            <ProjectsWrapper>
              <ProjectCard
                title="Web &amp; SaaS"
                link="javascript:"
                bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
              >
                MadeFreshly, ArtMining, Joonomy, BanglaTribune, OMIS, GuitarMotel,
                StockZoom, ThinkAsia etc
              </ProjectCard>
              <ProjectCard
                title="Tourism"
                link="javascript:"
                bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
              >
                TicketChai, Meghdoot Booking Engine, Meghdoot Note etc
              </ProjectCard>
              <ProjectCard
                title="Fintech"
                link="javascript:"
                bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
              >
                DBBL Nexus Pay, Kona Pay, BigCoin Machine etc
              </ProjectCard>
              <ProjectCard
                title="VOIP"
                link="javascript:"
                bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
              >
                !dEa Messenger, MajicHat etc
              </ProjectCard>
              <ProjectCard
                title="IOT &amp; ML"
                link="javascript:"
                bg="linear-gradient(to right, #3C3B3F 0%, #605C3C 100%)"
              >
                BenyHome, MoonLight, TweetTwin etc
              </ProjectCard>
              <ProjectCard
                title="Streaming"
                link="javascript:"
                bg="linear-gradient(to right, #42275a 0%, #734b6d 100%)"
              >
                BeatMug, Dostums, NetFlixon etc
              </ProjectCard>
            </ProjectsWrapper>
          </Projects>
          <About offset={3}>
            <Title>About</Title>
            <AboutHero>
              <Avatar src={avatar} alt="Mazhar Ahmed" />
              <AboutSub>
                I'm working for more than {new Date().getFullYear() - 2007} years
                from Tampere, Finland. I am most comfortable working with Computer
                Engineering, Business Consultancy and Creative Design.
                I'm a Polyglot Programmer.
              </AboutSub>
            </AboutHero>
            <AboutDesc>
              I am an Expert in Web, Payment, Tourism, Streaming and VOIP Technologies.
              I help startups building their team, organization structure and
              brand value. Successfully contributed to several company's
              management in past. Now a days, I provide consultancy to the
              startups on "How to Grow". Besides that, I took my role as a CTO,
              Director in my career and drive the organizations to success in
              technology related solutions.
            </AboutDesc>
          </About>
          <Contact offset={4}>
            <Inner>
              <Title>Get in touch</Title>
              <ContactText>
                Say <a href="mailto:info@mazharahmed.me">Hi</a> or find me on other platforms:{' '}
                <a href="https://github.com/mazhar266">Github</a>,{' '}
                <a href="https://dribbble.com/humblemaze">Dribbble</a> &{' '}
                <a href="https://www.linkedin.com/in/humblemaze/">Linkedin</a>
              </ContactText>
            </Inner>
            <Footer>
              &copy; {new Date().getFullYear()} by Mazhar Ahmed. Powered by{' '}
              <a href="https://github.com/LekoArts/gatsby-starter-portfolio-cara" target="_blank">
                Cara Gatsby Portfolio
              </a>.
            </Footer>
          </Contact>
        </Parallax>
      </>
    )
  }
}

export default Index
