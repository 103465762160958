const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Mazhar - Personal Portfolio', // Navigation and Site Title
  siteTitleAlt: 'Mazhar', // Alternative Site title for SEO
  siteTitleShort: 'Mazhar', // short_name for manifest
  siteHeadline: 'Tech Expert, Design Lover, Product Enthusiast.', // Headline for schema.org JSONLD
  siteUrl: 'https://www.iammaze.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Tech Expert, Design Lover, Product Enthusiast.',
  author: 'Mazhar Ahmed', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@humblemaze', // Twitter Username
  ogSiteName: 'humblemaze', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-36129504-1',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
